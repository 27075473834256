import { CallToAction } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { InnerAccordionSection } from '@rsa-digital/evo-shared-components/components/Accordion';
import AddButton from '@rsa-digital/evo-shared-components/components/AddButton';
import ExpandableTable, {
  TableRow,
} from '@rsa-digital/evo-shared-components/components/ExpandableTable';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import {
  QuoteAdditionalDriverDetails,
  QuoteBaseDriverDetails,
} from 'api/quote/quoteRequest';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import AdditionalDriverDetails from 'components/PolicyDetails/AdditionalDriverDetails';
import useCheckAdditionalDriversDetails from 'components/PolicyDetails/AdditionalDriverDetails/data';
import PolicyHolderDetails from 'components/PolicyDetails/PolicyholderDetails';
import {
  trackAccordionCollapse,
  trackAccordionExpand,
  trackButtonClick,
} from 'helpers/eventTracking';
import { isPolicyReadonly } from 'helpers/policyHelpers';
import { Policy } from 'state/policy/policy';
import { AddDriverSection, Wrapper } from './styles';

type DriverDetailsData = {
  csMyPolicyPage: {
    driver_details: {
      policyholder: {
        change_address_button_text: string;
        change_address_screenreader_text: string;
      };
    };
  };
  csPolicyDetails: {
    drivers_details: {
      information_labels_policyholder: {
        email_address: string;
        telephone: string;
      };
      main_driver_suffix: string;
      button_labels: {
        add_driver_text: string;
        add_driver_screenreader_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csMyPolicyPage {
      driver_details {
        heading
        policyholder {
          change_address_button_text
          change_address_screenreader_text
        }
      }
    }
    csPolicyDetails {
      drivers_details {
        information_labels_policyholder {
          email_address
          telephone
        }
        main_driver_suffix
        button_labels {
          add_driver_text
          add_driver_screenreader_text
        }
      }
    }
  }
`;

type DriverDetailsSectionProps = {
  policy: Policy;
  initiallyExpanded?: boolean;
  changeAddressAction: MouseEventHandler;
  addDriverAction: MouseEventHandler;
  editDriverAction: (driverId: string) => MouseEventHandler;
  removeDriverAction?: (driver: QuoteAdditionalDriverDetails) => MouseEventHandler;
};

const getDriverTitle = (
  driver: QuoteBaseDriverDetails,
  mainDriverSuffix: string
): string =>
  `${driver.firstName} ${driver.lastName} ${driver.isMainDriver ? mainDriverSuffix : ''}`;

const DriverDetailsSection: React.FC<DriverDetailsSectionProps> = ({
  policy,
  initiallyExpanded = false,
  changeAddressAction,
  addDriverAction,
  editDriverAction,
  removeDriverAction,
}) => {
  const {
    csPolicyDetails: {
      drivers_details: {
        information_labels_policyholder: labels,
        main_driver_suffix,
        button_labels: buttonLabels,
      },
    },
    csMyPolicyPage: {
      driver_details: { policyholder: buttonText },
    },
  } = useStaticQuery<DriverDetailsData>(query);

  const editPolicyHolderAddressCta: CallToAction = {
    displayText: buttonText.change_address_button_text,
    screenReaderText: buttonText.change_address_screenreader_text,
    onClick: (e) => {
      trackButtonClick(
        'changePolicyHolderAddressCta',
        buttonText.change_address_button_text
      );
      changeAddressAction(e);
    },
  };

  const additionalDriversData = useCheckAdditionalDriversDetails(policy);

  const policyHolderExtraInfo: TableRow[] = [
    {
      title: labels.email_address,
      value: policy.account.email,
    },
    {
      title: labels.telephone,
      value: policy.account.primaryPhone,
    },
  ];

  const readonly = isPolicyReadonly(policy);

  return (
    <>
      <div data-pii-mask>
        <InnerAccordionSection
          title={getDriverTitle(policy.policyHolder, main_driver_suffix || '')}
          sectionId="policyholder-section"
          entryId="policyholder-entry"
          initiallyDisplayEntry={initiallyExpanded}
          onCollapse={trackAccordionCollapse(
            getDriverTitle(policy.policyHolder, main_driver_suffix || '')
          )}
          onExpand={trackAccordionExpand(
            getDriverTitle(policy.policyHolder, main_driver_suffix || '')
          )}>
          <Wrapper>
            <ExpandableTable
              initialRows={policyHolderExtraInfo}
              collapsibleRows={[]}
              expanded={false}
            />
            <PolicyHolderDetails
              policy={policy}
              changeCta={editPolicyHolderAddressCta}
              readonly={readonly}
            />
          </Wrapper>
        </InnerAccordionSection>
        {additionalDriversData.map(
          ({ additionalDriver, initialRows, collapsibleRows }, index) => (
            <InnerAccordionSection
              title={getDriverTitle(
                policy.additionalDrivers[index],
                main_driver_suffix || ''
              )}
              sectionId={`additional-driver-${index}-section`}
              entryId={`additional-driver-${index}-entry`}
              initiallyDisplayEntry={false}
              onCollapse={trackAccordionCollapse(
                getDriverTitle(additionalDriver, main_driver_suffix || '')
              )}
              onExpand={trackAccordionExpand(
                getDriverTitle(additionalDriver, main_driver_suffix || '')
              )}
              key={generateKeyForElement(additionalDriver)}>
              <Wrapper>
                <AdditionalDriverDetails
                  initialRows={initialRows}
                  collapsibleRows={collapsibleRows}
                  editDriverAction={editDriverAction(additionalDriver.driverId)}
                  removeDriverAction={
                    !policy.additionalDrivers[index].isMainDriver && removeDriverAction
                      ? removeDriverAction(policy.additionalDrivers[index])
                      : undefined
                  }
                  readonly={readonly}
                />
              </Wrapper>
            </InnerAccordionSection>
          )
        )}
      </div>
      {addDriverAction && !readonly && (
        <AddDriverSection>
          <AddButton
            screenReaderText={buttonLabels.add_driver_screenreader_text}
            onClick={(e) => {
              trackButtonClick(
                'addDriverAction',
                buttonLabels.add_driver_screenreader_text
              );
              addDriverAction(e);
            }}>
            {buttonLabels.add_driver_text}
          </AddButton>
        </AddDriverSection>
      )}
    </>
  );
};

export default DriverDetailsSection;
