import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { trackButtonClick, trackModalOpen } from 'helpers/eventTracking';
import { mtaRoutes } from 'helpers/routingHelper';

type ContinueMtaChangeModalProps = {
  policyNumber: string;
  onClose: MouseEventHandler;
};

export type CsContinueMtaChangeModal = {
  csMyPolicyPage: {
    continue_mta_change_modal: {
      heading: string;
      body: string;
      cta_text: string;
      cta_screenreader_text: string;
    };
  };
};

const query = graphql`
  query {
    csMyPolicyPage {
      continue_mta_change_modal {
        heading
        body
        cta_text
        cta_screenreader_text
      }
    }
  }
`;

const ContinueMtaChangeModal: React.FC<ContinueMtaChangeModalProps> = ({
  policyNumber,
  onClose,
}) => {
  const content = useStaticQuery<CsContinueMtaChangeModal>(query);
  const modalCopy = content.csMyPolicyPage.continue_mta_change_modal;

  return (
    <ConfirmationModal
      data-cy="Continue MTA change modal"
      heading={modalCopy.heading}
      body={modalCopy.body}
      onClose={(e) => {
        trackButtonClick('closeMTAChangeModal', modalCopy.heading);
        onClose(e);
      }}
      confirmCta={{
        displayText: modalCopy.cta_text,
        screenReaderText: modalCopy.cta_screenreader_text,
        onClick: () => {
          trackModalOpen(modalCopy.cta_text);
          navigate(mtaRoutes.checkYourDetails(policyNumber));
        },
      }}
    />
  );
};

export default ContinueMtaChangeModal;
