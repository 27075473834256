import Accordion, {
  AccordionEntry,
} from '@rsa-digital/evo-shared-components/components/Accordion';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import LoadPolicyWrapper from 'components/ApiRequestWrapper/LoadPolicyWrapper';
import Layout from 'components/Layout';
import DocumentDownloadSection from 'components/MyPolicy/DocumentDownloadSection';
import PolicyHero from 'components/PolicyHero';
import { trackAccordionCollapse, trackAccordionExpand } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/usePageTracking';
import { usePolicyData } from 'state/policy/policy';
import { CsIcon, CsMeta } from 'types/contentStack';

type csInactivePolicyPage = {
  csMyPolicyPage: {
    meta: CsMeta;
  };
  csPolicyDetails: {
    policy_accordion_sections: {
      document_download: {
        heading: string;
        icon: [CsIcon];
      };
    };
  };
};

const query = graphql`
  query {
    csMyPolicyPage {
      meta {
        meta_title
      }
    }
    csPolicyDetails {
      policy_accordion_sections {
        document_download {
          heading
          icon {
            icon_code
          }
        }
      }
    }
  }
`;

const InactivePolicyPage: React.FC<{ policyNumber: string }> = ({ policyNumber }) => {
  const policyData = usePolicyData();
  const { csMyPolicyPage, csPolicyDetails } = useStaticQuery<csInactivePolicyPage>(query);

  usePageTracking(csMyPolicyPage.meta.meta_title, !!policyData);

  const inactivePolicyAccordionEntries: AccordionEntry[] = policyData
    ? [
        {
          title: csPolicyDetails.policy_accordion_sections.document_download.heading,
          content: (
            <DocumentDownloadSection
              policyNumber={policyNumber}
              icon={csPolicyDetails.policy_accordion_sections.document_download.icon}
            />
          ),
          onCollapse: trackAccordionCollapse(
            csPolicyDetails.policy_accordion_sections.document_download.heading
          ),
          onExpand: trackAccordionExpand(
            csPolicyDetails.policy_accordion_sections.document_download.heading
          ),
          initiallyDisplay: true,
        },
      ]
    : [];

  return (
    <Layout meta={csMyPolicyPage.meta} pageType="accountArea">
      <LoadPolicyWrapper policyNumber={policyNumber} forceLoad>
        <PolicyHero
          policy={policyData}
          data-cy="InactivePolicyHero"
          onCancelMta={() => {}}
        />
        <Grid>
          <GridItem desktop={10} tabletLandscape={10}>
            <Accordion
              initiallyDisplayEntries
              accordionEntries={inactivePolicyAccordionEntries}
            />
          </GridItem>
        </Grid>
      </LoadPolicyWrapper>
    </Layout>
  );
};

export default InactivePolicyPage;
