import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import React from 'react';
import InactivePolicyPage from 'components/MyPolicy/InactivePolicyPage';
import PolicyPage from 'components/MyPolicy/MyPolicyPage';
import { isPolicyInactive } from 'helpers/policyHelpers';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { usePolicyData } from 'state/policy/policy';

const MyPolicies: React.FC<PolicyNumberProps> = ({ policyNumber }) => {
  const policyData = usePolicyData();

  return (
    <>
      {!policyData && <LoadingOverlay loadingMessage="Loading policy, please wait" />}
      {policyData && isPolicyInactive(policyData) ? (
        <InactivePolicyPage policyNumber={policyNumber} />
      ) : (
        <PolicyPage policyNumber={policyNumber} />
      )}
    </>
  );
};

export default withPolicyNumber(MyPolicies);
