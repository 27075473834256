import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const RichTextWithTopMargin = styled(RichTextWithModal)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4.5)};
  `}
`;
