import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { trackModalOpen } from 'helpers/eventTracking';
import { canPolicyBeRenewed } from 'helpers/policyHelpers';
import { MtaStatus } from 'state/mta/mtaQuote';
import { Policy, RenewalStatus } from 'state/policy/policy';
import {
  ColumnFlexWrapper,
  PolicyMainDetailsWrapper,
  RowFlexWrapper,
  StyledRichText,
} from './styles';
import {
  ContinueWithMtaChange,
  ContinueWithRenewalChange,
} from '../ContinueWithChangeSection';
import PolicyCardPanel from '../Panel';
import PolicyCardWrapper from '../PolicyCardWrapper';
import RenewNowCta from '../RenewNowCta';
import SummaryAndRegistration from '../SummaryAndRegistration';
import usePolicyCardData from '../usePolicyCardData';

type PolicyPagePolicyCardProps = {
  policy: Policy;
  onCancelMta: () => void;
} & ComponentProps;

const PolicyPagePolicyCard: React.FC<PolicyPagePolicyCardProps> = ({
  policy,
  onCancelMta,
  ...props
}) => {
  const { content, policyIcon } = usePolicyCardData(policy);

  const renewalStatus = policy.renewalSummary?.renewalStatus;
  const policyCanBeRenewed = canPolicyBeRenewed(policy);
  const renewalInProgress =
    policy.renewalSummary?.renewalStatus === RenewalStatus.InProgress;
  const mtaInProgress = policy.mtaSummary?.mtaStatus === MtaStatus.InProgress;

  return (
    <PolicyCardWrapper policy={policy} onCancelMta={onCancelMta} {...props}>
      {(requestHandler, toggleCancelMtaModal) => (
        <GridItem desktop={10} tabletLandscape={10}>
          <PolicyCardPanel policy={policy} content={content} displayOutsetShadow>
            {policyIcon?.icon_code && (
              <Icon name={policyIcon.icon_code} size="xx-large" color={colors.core01} />
            )}
            <PolicyMainDetailsWrapper>
              <SummaryAndRegistration policy={policy} headingAs="h1" />
              <RowFlexWrapper isReadyPolicyState={renewalStatus === RenewalStatus.Ready}>
                <ColumnFlexWrapper>
                  <StyledRichText
                    html={content.csMyAccount.my_policies.policy_details_text}
                  />
                  {policyCanBeRenewed && (
                    <RenewNowCta
                      policy={policy}
                      content={content.csMyAccount.my_policies.renew_now}
                      requestHandler={requestHandler}
                      isPolicyPage
                    />
                  )}
                </ColumnFlexWrapper>
              </RowFlexWrapper>
            </PolicyMainDetailsWrapper>
            {renewalInProgress && (
              <ContinueWithRenewalChange
                content={content}
                policy={policy}
                divider="top"
                ctaDisabled={policy.isInArrears}
              />
            )}
            {/* Renewal content takes precedence over MTA content */}
            {mtaInProgress && !renewalInProgress && (
              <ContinueWithMtaChange
                content={content}
                policy={policy}
                onCancelMta={() => {
                  toggleCancelMtaModal();
                  trackModalOpen('cancelMtaModal');
                }}
                divider="top"
                ctaDisabled={policy.isInArrears}
              />
            )}
          </PolicyCardPanel>
        </GridItem>
      )}
    </PolicyCardWrapper>
  );
};

export default PolicyPagePolicyCard;
