import { CallToAction } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { AccordionItemWrapper } from 'components/AccordionItemWrapper';
import CarDetails from 'components/PolicyDetails/CarDetails';
import { trackButtonClick } from 'helpers/eventTracking';
import { isPolicyReadonly } from 'helpers/policyHelpers';
import { Policy } from 'state/policy/policy';

type ButtonTextQuery = {
  csMyPolicyPage: {
    car_details: {
      change_button_text: string;
      change_button_screenreader_text: string;
    };
  };
};

const buttonTextQuery = graphql`
  query {
    csMyPolicyPage {
      car_details {
        change_button_text
        change_button_screenreader_text
      }
    }
  }
`;

type CarDetailsSectionProps = {
  policy: Policy;
  initiallyExpanded?: boolean;
  changeCarAction: MouseEventHandler;
};

const CarDetailsSection: React.FC<CarDetailsSectionProps> = ({
  policy,
  initiallyExpanded,
  changeCarAction,
}) => {
  const { change_button_text, change_button_screenreader_text } = useStaticQuery<
    ButtonTextQuery
  >(buttonTextQuery).csMyPolicyPage.car_details;

  const changeCta: CallToAction = {
    displayText: change_button_text,
    screenReaderText: change_button_screenreader_text,
    onClick: (e) => {
      trackButtonClick('changeCarDetailsCta', change_button_text);
      changeCarAction(e);
    },
  };

  const readonly = isPolicyReadonly(policy);

  return (
    <AccordionItemWrapper>
      <CarDetails
        policy={policy}
        changeCta={changeCta}
        initiallyExpanded={initiallyExpanded}
        readonly={readonly}
      />
    </AccordionItemWrapper>
  );
};

export default CarDetailsSection;
