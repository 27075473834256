import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { trackModalOpen } from 'helpers/eventTracking';
import { canPolicyBeRenewed, getTierLogoForPolicy } from 'helpers/policyHelpers';
import { MtaStatus } from 'state/mta/mtaQuote';
import { Policy, RenewalStatus } from 'state/policy/policy';
import {
  ColumnFlexWrapper,
  LogoImagePolicyPage,
  PolicyMainDetailsWrapper,
  SmallerDivider,
  StyledInactiveRichText,
  StyledRichText,
  SummaryAndRegistrationWithNoMargin,
  TextWrapper,
} from './styles';
import {
  ContinueWithMtaChange,
  ContinueWithRenewalChange,
} from '../ContinueWithChangeSection';
import PolicyCardPanel from '../Panel';
import PolicyCardWrapper from '../PolicyCardWrapper';
import RenewNowCta from '../RenewNowCta';
import { CsAccountTieredLogo } from '../types';
import usePolicyCardData from '../usePolicyCardData';

type TieredPolicyPagePolicyCardProps = {
  policy: Policy;
  isInactive: boolean;
  onCancelMta: () => void;
} & ComponentProps;

const query = graphql`
  query {
    csTieredMyAccount {
      tier_logos {
        essentials_logo {
          ...CsAsset
        }
        standard_logo {
          ...CsAsset
        }
        extra_logo {
          ...CsAsset
        }
      }
    }
  }
`;

const TieredPolicyPagePolicyCard: React.FC<TieredPolicyPagePolicyCardProps> = ({
  policy,
  isInactive,
  onCancelMta,
  ...props
}) => {
  const logoContent = useStaticQuery<CsAccountTieredLogo>(query);
  const { content } = usePolicyCardData(policy);

  const policyCanBeRenewed = canPolicyBeRenewed(policy);
  const renewalInProgress =
    policy.renewalSummary?.renewalStatus === RenewalStatus.InProgress;
  const mtaInProgress = policy.mtaSummary?.mtaStatus === MtaStatus.InProgress;

  const logo = getTierLogoForPolicy(policy, logoContent);

  return (
    <PolicyCardWrapper policy={policy} onCancelMta={onCancelMta} {...props}>
      {(requestHandler, toggleCancelMtaModal) => (
        <GridItem desktop={10} tabletLandscape={10}>
          <PolicyCardPanel policy={policy} content={content} displayOutsetShadow>
            <PolicyMainDetailsWrapper>
              {logo && <LogoImagePolicyPage image={logo} isInactive={isInactive} />}
              <SmallerDivider />
              <TextWrapper>
                <SummaryAndRegistrationWithNoMargin
                  policy={policy}
                  headingAs="h1"
                  isInactive={isInactive}
                />
                <ColumnFlexWrapper>
                  {isInactive ? (
                    <StyledInactiveRichText
                      html={content.csMyAccount.inactive_policies.policy_details_text}
                    />
                  ) : (
                    <StyledRichText
                      html={content.csMyAccount.my_policies.policy_details_text}
                    />
                  )}
                  {!isInactive && policyCanBeRenewed && (
                    <RenewNowCta
                      policy={policy}
                      content={content.csMyAccount.my_policies.renew_now}
                      requestHandler={requestHandler}
                      isPolicyPage
                    />
                  )}
                </ColumnFlexWrapper>
              </TextWrapper>
            </PolicyMainDetailsWrapper>
            {!isInactive && renewalInProgress && (
              <ContinueWithRenewalChange
                content={content}
                policy={policy}
                divider="top"
                ctaDisabled={policy.isInArrears}
              />
            )}
            {/* Renewal content takes precedence over MTA content */}
            {!isInactive && mtaInProgress && !renewalInProgress && (
              <ContinueWithMtaChange
                content={content}
                policy={policy}
                onCancelMta={() => {
                  toggleCancelMtaModal();
                  trackModalOpen('cancelMtaModal');
                }}
                divider="top"
                ctaDisabled={policy.isInArrears}
              />
            )}
          </PolicyCardPanel>
        </GridItem>
      )}
    </PolicyCardWrapper>
  );
};

export default TieredPolicyPagePolicyCard;
