import Accordion, {
  AccordionEntry,
} from '@rsa-digital/evo-shared-components/components/Accordion';
import ActionCard from '@rsa-digital/evo-shared-components/components/ActionCard/ActionCard';
import CardGrid from '@rsa-digital/evo-shared-components/components/CardGrid/CardGrid';
import {
  Grid,
  GridItem,
  GridItemProps,
} from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { QuoteAdditionalDriverDetails } from 'api/quote/quoteRequest';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import React, { MouseEventHandler, PropsWithChildren, useReducer, useState } from 'react';
import LoadPolicyWrapper from 'components/ApiRequestWrapper/LoadPolicyWrapper';
import YourCoverSection from 'components/CheckDetails/YourCoverSection';
import CustomModal from 'components/CustomModal';
import Layout from 'components/Layout';
import CarDetailsSection from 'components/MyPolicy/CarDetailsSection';
import ConfirmRemoveDriverModal from 'components/MyPolicy/ConfirmRemoveDriverModal';
import ContinueMtaChangeModal from 'components/MyPolicy/ContinueMtaChangeModal';
import DocumentDownloadSection from 'components/MyPolicy/DocumentDownloadSection';
import DriverDetailsSection from 'components/MyPolicy/DriverDetailsSection';
import ExcessSection from 'components/MyPolicy/ExcessSection';
import OptionalExtrasSection from 'components/MyPolicy/OptionalExtrasSection';
import PolicyDetailsSection from 'components/MyPolicy/PolicyDetailsSection';
import SelectDriverToRemoveModal from 'components/MyPolicy/SelectDriverToRemoveModal';
import PolicyHero from 'components/PolicyHero';
import PolicyManagement from 'components/PolicyManagement';
import useRichTextWithModal from 'components/RichTextWithModal/useRichTextwithModal';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  trackAccordionCollapse,
  trackAccordionExpand,
  trackButtonClick,
  trackModalOpen,
} from 'helpers/eventTracking';
import { isPolicyReadonly } from 'helpers/policyHelpers';
import { getNoChangesCanBeMadeSectionContent } from 'helpers/readonlyPolicyHelpers';
import { mtaRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { initialAddDriverMtaDetails } from 'state/formData/mta/addDriver';
import { initialEditAddressMtaDetails } from 'state/formData/mta/editAddress';
import { initialEditDriverMtaDetails } from 'state/formData/mta/editDriver';
import { initialVehicleDetails } from 'state/formData/vehicleDetails';
import { MtaFormType, MtaState, useMtaState } from 'state/mta/mta';
import { MtaStatus } from 'state/mta/mtaQuote';
import { Policy, UPDATE_POLICY, usePolicyData } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';
import { CsIcon, CsMeta } from 'types/contentStack';
import {
  FlexWrapper,
  NoChangesCanBeMade,
  SectionHeading,
  StyledLinkWithIcon,
  StyledText,
} from './styles';

type MtaActionCardTypes = Exclude<MtaFormType, 'Edit a driver'>;

type ActionCardType = {
  heading: string;
  url: string;
  mta_type?: MtaActionCardTypes;
  modal_id?: string;
};

export type NoChangesCanBeMadeContent = {
  future_policy_start_date: string;
  policy_in_arrears: string;
  upcoming_confirmed_mta: string;
  active_renewal: string;
};

type MyPolicyPage = {
  csMyPolicyPage: {
    meta: CsMeta;
    quick_links: {
      update_policy_link_text: string;
      update_policy_link_icon: [CsIcon];
      make_or_track_a_claim_link_text: string;
      make_or_track_a_claim_link_url: string;
      make_or_track_a_claim_link_icon: [CsIcon];
    };
    no_changes_can_be_made: NoChangesCanBeMadeContent;
  };
  csFcaMyPolicyPage: {
    make_a_change: {
      make_a_change_heading: string;
      make_a_change_action_cards: ActionCardType[];
    };
  };
  csPolicyDetails: {
    policy_accordion_sections: {
      policy_details: {
        heading: string;
      };
      optional_extras: {
        heading: string;
      };
      excess: {
        heading: string;
      };
      car_details: {
        heading: string;
      };
      driver_details: {
        heading: string;
      };
      document_download: {
        heading: string;
        icon: [CsIcon];
      };
    };
  };
};

const query = graphql`
  query {
    csMyPolicyPage {
      meta {
        meta_title
      }
      quick_links {
        update_policy_link_text
        update_policy_link_icon {
          icon_code
        }
        make_or_track_a_claim_link_text
        make_or_track_a_claim_link_url
        make_or_track_a_claim_link_icon {
          icon_code
        }
      }
      no_changes_can_be_made {
        future_policy_start_date
        policy_in_arrears
        upcoming_confirmed_mta
        active_renewal
      }
    }
    csFcaMyPolicyPage {
      make_a_change {
        make_a_change_heading
        make_a_change_action_cards {
          heading
          url
          mta_type
          modal_id
        }
      }
    }
    csPolicyDetails {
      policy_accordion_sections {
        policy_details {
          heading
        }
        optional_extras {
          heading
        }
        excess {
          heading
        }
        car_details {
          heading
        }
        driver_details {
          heading
        }
        document_download {
          heading
          icon {
            icon_code
          }
        }
      }
    }
  }
`;

type MtaAction = {
  [T in MtaActionCardTypes]: (
    e: React.MouseEvent<Element, MouseEvent>,
    mtaUpdater: (update: Partial<MtaState>) => void
  ) => void;
};

const hideRemoveDriverActionCardIfUnusable = (
  actionCards: ActionCardType[],
  policy?: Policy
): ActionCardType[] => {
  // remove delete driver MTA if no additional drivers that are *not* the main driver
  const hasNonMainAdditionalDriver = policy?.additionalDrivers.some(
    (driver) => !driver.isMainDriver
  );
  if (hasNonMainAdditionalDriver) {
    return actionCards;
  }
  return actionCards.filter((card) => card.mta_type !== 'Remove a driver');
};

export const columnSpanCalculator: () => GridItemProps = () => ({
  // mobile cards are always full-width
  tabletPortrait: 4,
  tabletLandscape: 5,
  desktop: 5,
});

const ActionCardGrid: React.FC<PropsWithChildren<ComponentProps>> = ({
  children,
  ...props
}) => (
  <CardGrid
    {...componentProps(props)}
    columnSpanCalculator={columnSpanCalculator}
    alignLeft>
    {children}
  </CardGrid>
);

const MyPolicies: React.FC<{ policyNumber: string }> = ({ policyNumber }) => {
  const policyData = usePolicyData();
  const dispatch = useDispatch();
  const [, updateMtaState] = useMtaState();
  const { csMyPolicyPage, csFcaMyPolicyPage, csPolicyDetails } = useStaticQuery<
    MyPolicyPage
  >(query);
  const actionCards = hideRemoveDriverActionCardIfUnusable(
    csFcaMyPolicyPage.make_a_change.make_a_change_action_cards,
    policyData ?? undefined
  );

  const updatePolicyLinkIcon = unwrapSingleton(
    csMyPolicyPage.quick_links.update_policy_link_icon
  );
  const makeTrackClaimLinkIcon = unwrapSingleton(
    csMyPolicyPage.quick_links.make_or_track_a_claim_link_icon
  );

  const [displayContinueMtaChangeModal, toggleDisplayContinueMtaChangeModal] = useReducer(
    (state) => !state,
    false
  );

  const [
    displaySelectDriverToRemoveModal,
    toggleDisplaySelectDriverToRemoveModal,
  ] = useReducer((state) => !state, false);

  const { modalId, setModalId } = useRichTextWithModal();

  const readonly = policyData && isPolicyReadonly(policyData);
  const noChangesCanBeMadeContent =
    policyData &&
    getNoChangesCanBeMadeSectionContent(
      policyData,
      csMyPolicyPage.no_changes_can_be_made
    );

  const updateMta = (updateFn: () => void): void => {
    if (policyData?.mtaSummary?.mtaStatus === MtaStatus.InProgress) {
      toggleDisplayContinueMtaChangeModal();
      trackModalOpen('continueMtaChange');
    } else {
      updateFn();
    }
  };

  const changeCarAction: MouseEventHandler = (e) => {
    e.preventDefault();
    updateMta(() => {
      updateMtaState({
        mtaInProgress: 'Change car',
        changeCar: initialVehicleDetails,
        migratedFields: policyData?.migratedFields,
      });
      navigate(mtaRoutes.changeCar(policyNumber));
    });
  };

  const editAddressAction: MouseEventHandler = (e) => {
    e.preventDefault();
    updateMta(() => {
      updateMtaState({
        mtaInProgress: 'Edit address',
        editAddress: initialEditAddressMtaDetails,
        migratedFields: policyData?.migratedFields,
      });
      navigate(mtaRoutes.changeAddress(policyNumber));
    });
  };

  const addDriverAction: MouseEventHandler = (e) => {
    e.preventDefault();
    updateMta(() => {
      updateMtaState({
        mtaInProgress: 'Add a driver',
        addDriver: initialAddDriverMtaDetails,
      });
      navigate(mtaRoutes.addDriver(policyNumber));
    });
  };

  const editDriverAction = (driverId: string): MouseEventHandler => (e) => {
    e.preventDefault();
    updateMta(() => {
      updateMtaState({
        mtaInProgress: 'Edit a driver',
        editDriver: { ...initialEditDriverMtaDetails, driverId },
      });
      navigate(mtaRoutes.editDriver(policyNumber, driverId));
    });
  };

  const [
    driverToRemove,
    setDriverToRemove,
  ] = useState<QuoteAdditionalDriverDetails | null>(null);

  const removeDriverAction = (
    driver: QuoteAdditionalDriverDetails
  ): MouseEventHandler => (e) => {
    e.preventDefault();
    updateMta(() => {
      setDriverToRemove(driver);
    });
  };

  const selectDriverToRemoveAction: MouseEventHandler = (e) => {
    e.preventDefault();
    updateMta(() => {
      toggleDisplaySelectDriverToRemoveModal();
    });
  };

  const confirmRemoveDriverAction: (driverId: string) => MouseEventHandler = (
    driverId
  ) => (e) => {
    e.preventDefault();
    updateMtaState({
      mtaInProgress: 'Remove a driver',
      removeDriver: { driverId },
    });
    navigate(mtaRoutes.removeDriver(policyNumber, driverId));
  };

  const mtaSwitch: MtaAction = {
    'Change car': changeCarAction,
    'Edit address': editAddressAction,
    'Add a driver': addDriverAction,
    'Remove a driver': selectDriverToRemoveAction,
  };

  const accordionEntries: AccordionEntry[] = policyData
    ? [
        {
          title: csPolicyDetails.policy_accordion_sections.policy_details.heading,
          content: policyData.tieringInfo ? (
            <YourCoverSection quote={policyData} readOnly />
          ) : (
            <PolicyDetailsSection policy={policyData} />
          ),
          onCollapse: trackAccordionCollapse(
            csPolicyDetails.policy_accordion_sections.policy_details.heading
          ),
          onExpand: trackAccordionExpand(
            csPolicyDetails.policy_accordion_sections.policy_details.heading
          ),
        },
        {
          title: csPolicyDetails.policy_accordion_sections.optional_extras.heading,
          content: <OptionalExtrasSection policy={policyData} />,
          onCollapse: trackAccordionCollapse(
            csPolicyDetails.policy_accordion_sections.optional_extras.heading
          ),
          onExpand: trackAccordionExpand(
            csPolicyDetails.policy_accordion_sections.optional_extras.heading
          ),
        },
        {
          title: csPolicyDetails.policy_accordion_sections.document_download.heading,
          content: (
            <DocumentDownloadSection
              policyNumber={policyNumber}
              icon={csPolicyDetails.policy_accordion_sections.document_download.icon}
            />
          ),
          onCollapse: trackAccordionCollapse(
            csPolicyDetails.policy_accordion_sections.document_download.heading
          ),
          onExpand: trackAccordionExpand(
            csPolicyDetails.policy_accordion_sections.document_download.heading
          ),
        },
        {
          title: csPolicyDetails.policy_accordion_sections.excess.heading,
          content: <ExcessSection policy={policyData} />,
          onCollapse: trackAccordionCollapse(
            csPolicyDetails.policy_accordion_sections.excess.heading
          ),
          onExpand: trackAccordionExpand(
            csPolicyDetails.policy_accordion_sections.excess.heading
          ),
        },
        {
          title: csPolicyDetails.policy_accordion_sections.car_details.heading,
          content: (
            <CarDetailsSection policy={policyData} changeCarAction={changeCarAction} />
          ),
          onCollapse: trackAccordionCollapse(
            csPolicyDetails.policy_accordion_sections.car_details.heading
          ),
          onExpand: trackAccordionExpand(
            csPolicyDetails.policy_accordion_sections.car_details.heading
          ),
        },
        {
          title: csPolicyDetails.policy_accordion_sections.driver_details.heading,
          content: (
            <DriverDetailsSection
              policy={policyData}
              changeAddressAction={editAddressAction}
              addDriverAction={addDriverAction}
              editDriverAction={editDriverAction}
              removeDriverAction={removeDriverAction}
              initiallyExpanded
            />
          ),
          onCollapse: trackAccordionCollapse(
            csPolicyDetails.policy_accordion_sections.driver_details.heading
          ),
          onExpand: trackAccordionExpand(
            csPolicyDetails.policy_accordion_sections.driver_details.heading
          ),
        },
      ]
    : [];

  usePageTracking(csMyPolicyPage.meta.meta_title, !!policyData);

  return (
    <Layout meta={csMyPolicyPage.meta} pageType="accountArea">
      <LoadPolicyWrapper policyNumber={policyNumber} forceLoad>
        <CustomModal modalId={modalId} onClose={() => setModalId(null)} />
        <PolicyHero
          policy={policyData}
          data-cy="PolicyHero"
          onCancelMta={() => {
            if (policyData) {
              dispatch({
                type: UPDATE_POLICY,
                policyData: { ...policyData, mtaSummary: undefined },
              });
            }
          }}
        />
        <Grid>
          <GridItem desktop={10} tabletLandscape={10}>
            <FlexWrapper>
              {!readonly && (
                <StyledLinkWithIcon
                  data-cy="updatePolicyLink"
                  onClick={() => {
                    trackButtonClick(
                      'updatePolicyLink',
                      csMyPolicyPage.quick_links.update_policy_link_text
                    );
                    scrollToElement('make-a-change-section-heading', 20);
                  }}
                  icon={updatePolicyLinkIcon}>
                  <StyledText>
                    {csMyPolicyPage.quick_links.update_policy_link_text}
                  </StyledText>
                </StyledLinkWithIcon>
              )}
              <StyledLinkWithIcon
                data-cy="makeAClaimLink"
                onClick={() => {
                  trackButtonClick(
                    'makeAClaimLink',
                    csMyPolicyPage.quick_links.make_or_track_a_claim_link_text
                  );
                  navigate(
                    `${csMyPolicyPage.quick_links.make_or_track_a_claim_link_url}`
                  );
                }}
                icon={makeTrackClaimLinkIcon}>
                <StyledText>
                  {csMyPolicyPage.quick_links.make_or_track_a_claim_link_text}
                </StyledText>
              </StyledLinkWithIcon>
            </FlexWrapper>
            <Accordion
              initiallyDisplayEntries={false}
              accordionEntries={accordionEntries}
            />
            {readonly && noChangesCanBeMadeContent ? (
              <NoChangesCanBeMade
                data-cy="noChangesCanBeMadeCopy"
                html={noChangesCanBeMadeContent}
              />
            ) : (
              <>
                <SectionHeading id="make-a-change-section-heading">
                  {csFcaMyPolicyPage.make_a_change.make_a_change_heading}
                </SectionHeading>
                <ActionCardGrid>
                  {actionCards.map(({ heading, modal_id, mta_type, url }) => (
                    <ActionCard
                      heading={heading}
                      href={modal_id || mta_type ? '' : url}
                      onClick={(e) => {
                        trackButtonClick('makeAChange', heading);
                        if (mta_type && !modal_id) {
                          mtaSwitch[mta_type](e, updateMtaState);
                        }
                        if (modal_id) {
                          e.preventDefault();
                          setModalId(modal_id);
                        }
                      }}
                      key={heading}
                      id={`${kebabCase(heading)}-action-card`}
                    />
                  ))}
                </ActionCardGrid>
              </>
            )}
          </GridItem>
          <PolicyManagement />
        </Grid>
        {displayContinueMtaChangeModal && (
          <ContinueMtaChangeModal
            policyNumber={policyNumber}
            onClose={toggleDisplayContinueMtaChangeModal}
          />
        )}
        {driverToRemove && (
          <ConfirmRemoveDriverModal
            additionalDriver={driverToRemove}
            onConfirm={confirmRemoveDriverAction(driverToRemove.driverId)}
            onClose={() => setDriverToRemove(null)}
          />
        )}
        {displaySelectDriverToRemoveModal && (
          <SelectDriverToRemoveModal
            additionalDrivers={policyData?.additionalDrivers || []}
            onConfirm={confirmRemoveDriverAction}
            onClose={toggleDisplaySelectDriverToRemoveModal}
          />
        )}
      </LoadPolicyWrapper>
    </Layout>
  );
};

export default MyPolicies;
