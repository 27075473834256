import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { HeroWrapper } from '@rsa-digital/evo-shared-components/components/Hero/HeroPromo';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import InactivePolicyCard from 'components/PolicyCard/InactivePolicyCard';
import PolicyPagePolicyCard from 'components/PolicyCard/PolicyPagePolicyCard';
import TieredPolicyPagePolicyCard from 'components/PolicyCard/TieredPolicyPagePolicyCard';

export const StyledHeroWrapper = styled(HeroWrapper)`
  & {
    margin-bottom: ${spacing(4)};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacing(1)};
  margin-top: ${spacing(1)};
`;

export const HeroGrid = styled(Grid)`
  ${mediaQuery.tabletLandscape`
    padding: ${spacing(1)} 0 ${spacing(2)};
  `}

  ${mediaQuery.desktop`
    padding: ${spacing(5)} 0 ${spacing(4)};
  `}
`;

export const StyledButtonLink = styled(ButtonLink)`
  ${fonts.paragraphLarge}
  & {
    color: white;
    font-weight: normal;
  }

  display: block;
  margin-top: ${spacing(2)};
  text-decoration: underline;

  margin-bottom: ${spacing(12)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1)};
    margin-bottom: ${spacing(8)};
  `}

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
  &:focus {
    &:after {
      box-shadow: 0px 0px 0 2px ${colors.neutral08};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

const policyCardStyling = css`
  // this negative margin is to superimpose the policy on top of the chevron background
  margin-top: ${spacing(-11)};

  ${mediaQuery.tabletPortrait`
  margin-top: ${spacing(-14)};
  `}

  ${mediaQuery.desktop`
  margin-top: ${spacing(-20)};
  `}
`;

export const StyledPolicyCard = styled(PolicyPagePolicyCard)`
  ${policyCardStyling}
`;

export const StyledTieredPolicyCard = styled(TieredPolicyPagePolicyCard)`
  ${policyCardStyling}
`;

export const StyledInactivePolicyCard = styled(InactivePolicyCard)`
  ${policyCardStyling}
`;
