import { isComprehensiveCover } from 'api/businessLogic/quote';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { AccordionItemWrapper } from 'components/AccordionItemWrapper';
import CoverDate from 'components/PolicyDetails/CoverDate';
import RichTextWithModal from 'components/RichTextWithModal';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { Policy } from 'state/policy/policy';
import { CsIcon } from 'types/contentStack';

type PolicyDetailsData = {
  csPolicyDetails: {
    policy_details: {
      bullet_icon: [CsIcon];
      comprehensive: string;
      third_party_fire_and_theft: string;
    };
  };
};

const query = graphql`
  query {
    csPolicyDetails {
      policy_details {
        bullet_icon {
          icon_code
        }
        comprehensive
        third_party_fire_and_theft
      }
    }
  }
`;

const PolicyDetailsSection: React.FC<{ policy: Policy }> = ({ policy }) => {
  const content = useStaticQuery<PolicyDetailsData>(query).csPolicyDetails.policy_details;

  const bulletIcon = unwrapSingleton(content.bullet_icon);

  return (
    <AccordionItemWrapper>
      <RichTextWithModal
        html={
          isComprehensiveCover(policy.policy.coverWanted)
            ? content.comprehensive
            : content.third_party_fire_and_theft
        }
        customBulletIcon={bulletIcon?.icon_code}
      />
      <CoverDate
        startDate={policy.effectiveFromDate || ''}
        renewalDate={policy.renewalDate || ''}
      />
    </AccordionItemWrapper>
  );
};

export default PolicyDetailsSection;
