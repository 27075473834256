import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import SummaryAndRegistration from '../SummaryAndRegistration';
import { CarSummaryHeading, RegistrationText } from '../SummaryAndRegistration/styles';
import {
  columnFlexWrapperStyling,
  Divider,
  LogoImage,
  richTextStyling,
  StyledRichTextProps,
} from '../styles';

type InactiveCardProps = { isInactive: boolean };

export const StyledRichText = styled(RichTextWithModal)<StyledRichTextProps>`
  ${richTextStyling}

  p + p {
    margin-top: ${spacing(1)};
  }

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(1)};
  `}
`;

export const StyledInactiveRichText = styled(StyledRichText)`
  & {
    p {
      opacity: 0.4;
    }
  }
`;

export const ColumnFlexWrapper = styled.div`
  ${columnFlexWrapperStyling}

  /**
    * These styles are to fix the display on Internet Explorer.
    *
    * On IE only, this flexbox ends up with 0 width. To get around this, we replace the -ms-flex property
    * which only affect IE, and render it as a grid instead (-ms-grid also only affects IE) whose columns
    * have width according to their min-content.
    */
  ${mediaQuery.tabletPortrait`    
    -ms-flex: none;
    display: -ms-grid;
    -ms-grid-columns: min-content;
    width: 100%;
  `}

  ${mediaQuery.tabletLandscape`
        align-items: flex-end;
        flex: 1 0 40%;
  `}
`;

export const PolicyMainDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoImagePolicyPage = styled(LogoImage)<InactiveCardProps>`
  ${(props) =>
    props.isInactive &&
    css`
      opacity: 0.4;
      filter: grayscale(1);
    `}

  ${mediaQuery.tabletPortrait`
    align-self: center;
    margin-top: 0;
    padding-top: 0;
  `}
`;

export const TextWrapper = styled.div`
  ${mediaQuery.tabletLandscape`
        display: flex;
        flex-direction: row;
  `}
`;

export const SummaryAndRegistrationWithNoMargin = styled(SummaryAndRegistration)<
  InactiveCardProps
>`
  ${CarSummaryHeading} {
    margin-top: 0;
  }

  ${RegistrationText} {
    margin-bottom: ${spacing(2)};
    ${mediaQuery.tabletLandscape`
      margin-bottom: 0;
    `}
  }

  opacity: ${(props) => (props.isInactive ? 0.4 : 1)};
`;

export const SmallerDivider = styled(Divider)`
  margin: ${spacing(2)} 0;
`;
