import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Title = styled(RichText)`
  ${fonts.paragraphLarge};
  margin: 0;
  margin-top: ${spacing(2)};
  padding-top: ${spacing(2)};
  width: 100%;
  border-top: 2px solid ${colors.neutral06};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)};
    padding-top: ${spacing(3)};
  `}
`;
