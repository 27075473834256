import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import policyClient from 'api/policyClient';
import React, { useEffect, useReducer } from 'react';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { UPDATE_POLICY, usePolicyData } from 'state/policy/policy';
import useDispatch from 'state/useDispatch';

type LoadPolicyWrapperProps = {
  policyNumber: string;
  forceLoad?: boolean;
};

const LoadPolicyWrapper: React.FC<LoadPolicyWrapperProps> = ({
  policyNumber,
  forceLoad,
  children,
}) => {
  const policyData = usePolicyData();
  const { isLoading, requestHandler } = useApiRequestHandler();
  const dispatch = useDispatch();

  const isPolicyLoaded = policyData?.policyNumber === policyNumber;
  const shouldLoad = forceLoad || !isPolicyLoaded;

  const [hasLoaded, setLoaded] = useReducer(() => true, !shouldLoad);

  useEffect(() => {
    if (shouldLoad) {
      requestHandler(() =>
        policyClient.getPolicy(policyNumber).then((policy) => {
          dispatch({ type: UPDATE_POLICY, policyData: policy });
          setLoaded();
        })
      );
    }
  }, [policyNumber, requestHandler, dispatch, shouldLoad]);

  return isLoading || !hasLoaded ? (
    <LoadingOverlay loadingMessage="Loading policy, please wait" />
  ) : (
    <>{children}</>
  );
};

export default LoadPolicyWrapper;
