import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import {
  columnFlexWrapperStyling,
  richTextStyling,
  rowFlexWrapperStyling,
  StyledRichTextProps,
} from '../styles';

export const StyledRichText = styled(RichTextWithModal)<StyledRichTextProps>`
  ${richTextStyling}
  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(1)};
  `}
`;

export const RowFlexWrapper = styled.div<{ isReadyPolicyState?: boolean }>`
  ${rowFlexWrapperStyling}

  justify-content: flex-end;
  width: auto;
  flex-direction: row;

  ${(props) =>
    props.isReadyPolicyState &&
    css`
      ${mediaQuery.tabletLandscape`
        margin-top: ${spacing(-6)};
      `}
    `}
`;

export const ColumnFlexWrapper = styled.div`
  ${columnFlexWrapperStyling}
  justify-content: flex-end;

  /**
    * These styles are to fix the display on Internet Explorer.
    *
    * On IE only, this flexbox ends up with 0 width. To get around this, we replace the -ms-flex property
    * which only affect IE, and render it as a grid instead (-ms-grid also only affects IE) whose columns
    * have width according to their min-content.
    */
  ${mediaQuery.tabletPortrait`    
    -ms-flex: none;
    display: -ms-grid;
    -ms-grid-columns: min-content;
    width: 100%;
  `}
`;

export const PolicyMainDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQuery.tabletLandscape`
    flex-direction: row;
  `}
  justify-content: flex-start;
`;
