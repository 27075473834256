import { InnerAccordionSection } from '@rsa-digital/evo-shared-components/components/Accordion';
import {
  ActionCard,
  ActionCardAccordionGrid,
} from '@rsa-digital/evo-shared-components/components/ActionCard';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import policyClient from 'api/policyClient';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  trackAccordionCollapse,
  trackAccordionExpand,
  trackButtonClick,
} from 'helpers/eventTracking';
import replaceCsPlaceholders from 'helpers/replaceCsPlaceholders';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePolicyData } from 'state/policy/policy';
import { FormatOptions } from 'state/quote/quote';
import { CsIcon } from 'types/contentStack';
import { Title } from './styles';
import { Wrapper } from '../DriverDetailsSection/styles';

export type PolicyDocuments = {
  packageName: string;
  date: string;
  documents: {
    id: string;
    name: string;
    code: string;
    date: string;
  }[];
};

export type PolicyDocumentsData = {
  allCsPolicyDocuments: { nodes: { policy_code: string; summary: string }[] };
  csPolicyDetails: {
    policy_accordion_sections: {
      document_download: {
        policy_format_text: string;
      };
    };
  };
  csCheckYourDetails: {
    documents: {
      radio_button_text: {
        audio_cd: string;
        braille: string;
        large_print: string;
        online: string;
        paper: string;
      };
    };
  };
};

export const query = graphql`
  query {
    allCsPolicyDocuments {
      nodes {
        policy_code
        summary
      }
    }
    csPolicyDetails {
      policy_accordion_sections {
        document_download {
          policy_format_text
        }
      }
    }
    csCheckYourDetails {
      documents {
        radio_button_text {
          audio_cd
          braille
          large_print
          online
          paper
        }
      }
    }
  }
`;

const getEpochTimeInMs = (dateString: string): number => new Date(dateString).getTime();

const DocumentDownloadSection: React.FC<{ policyNumber: string; icon: [CsIcon] }> = ({
  policyNumber,
  icon,
}) => {
  const policyData = usePolicyData();
  const { allCsPolicyDocuments, csPolicyDetails, csCheckYourDetails } = useStaticQuery<
    PolicyDocumentsData
  >(query);
  const [allPolicyDocuments, setDocuments] = useState<PolicyDocuments[] | undefined>();
  const { isLoading, requestHandler } = useApiRequestHandler();

  const getSummary = (code: string): string => {
    const summary = allCsPolicyDocuments.nodes.find((node) => node.policy_code === code);
    return `${summary?.summary ?? ''}`;
  };

  const getDocumentsFormat = (documentFormat?: FormatOptions | null): string => {
    switch (documentFormat) {
      case FormatOptions.Paper:
        return `<b>${csCheckYourDetails.documents.radio_button_text.online}</b> plus ${csCheckYourDetails.documents.radio_button_text.paper}`;
      case FormatOptions.Braille:
        return `<b>${csCheckYourDetails.documents.radio_button_text.online}</b> plus ${csCheckYourDetails.documents.radio_button_text.braille}`;
      case FormatOptions.AudioCD:
        return `<b>${csCheckYourDetails.documents.radio_button_text.online}</b> plus ${csCheckYourDetails.documents.radio_button_text.audio_cd}`;
      case FormatOptions.Largeprint:
        return `<b>${csCheckYourDetails.documents.radio_button_text.online}</b> plus ${csCheckYourDetails.documents.radio_button_text.large_print}`;
      default:
        return `<b>${csCheckYourDetails.documents.radio_button_text.online}</b>`;
    }
  };

  const getAccordionSummary = (): string => {
    const policyFormat = getDocumentsFormat(policyData?.preferences.documentFormat);
    return replaceCsPlaceholders(csPolicyDetails, policyFormat, {
      documentPreferenceOption: (format: string) => format,
    }).policy_accordion_sections.document_download.policy_format_text;
  };

  useEffect(() => {
    if (!allPolicyDocuments) {
      requestHandler(() =>
        policyClient
          .getDocuments(policyNumber)
          .then((documentResults: PolicyDocuments[]) => {
            setDocuments(documentResults);
          })
      );
    }
  }, [allPolicyDocuments, policyNumber, requestHandler]);

  return (
    <>
      {isLoading && <LoadingOverlay loadingMessage="Loading policy documents" />}
      {allPolicyDocuments && (
        <>
          <Title data-cy="policydocument-summary" html={getAccordionSummary()} />
          {[...allPolicyDocuments]
            .sort((x, y) => getEpochTimeInMs(y.date) - getEpochTimeInMs(x.date))
            .map(({ packageName, date, documents }, packageIndex) => (
              <InnerAccordionSection
                title={`${packageName}, ${formatLongDateWithDayFirst(date)}`}
                sectionId={`policydocument-section-${packageIndex}`}
                entryId={`policydocument-entry-${packageIndex}`}
                key={packageName}
                initiallyDisplayEntry={packageIndex === 0}
                onCollapse={trackAccordionCollapse(`Policy document - ${packageName}`)}
                onExpand={trackAccordionExpand(`Policy document - ${packageName}`)}>
                <Wrapper>
                  <ActionCardAccordionGrid data-cy="ActionCards">
                    {[...documents]
                      .sort((x, y) => (x.date < y.date ? -1 : 1))
                      .map((document, index) => (
                        <ActionCard
                          data-cy={`ActionCard${index}`}
                          key={document.id}
                          heading={document.name}
                          subheading={formatLongDateWithDayFirst(document.date)}
                          body={getSummary(document.code)}
                          icon={unwrapSingleton(icon)?.icon_code}
                          href={`${process.env.GATSBY_API_URL}/policy/${policyNumber}/documents/${document.id}`}
                          onClick={() =>
                            trackButtonClick('policyDocumentDownload', document.name)
                          }
                          download
                        />
                      ))}
                  </ActionCardAccordionGrid>
                </Wrapper>
              </InnerAccordionSection>
            ))}
        </>
      )}
    </>
  );
};

export default DocumentDownloadSection;
