import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { AccordionItemWrapper } from 'components/AccordionItemWrapper';
import OptionalExtras from 'components/PolicyDetails/OptionalExtras';
import { Policy } from 'state/policy/policy';
import { RichTextWithTopMargin } from './styles';

type OptionalExtrasData = {
  csMyPolicyPage: {
    optional_extras: {
      information_text: string;
    };
  };
};

const query = graphql`
  query {
    csMyPolicyPage {
      optional_extras {
        information_text
      }
    }
  }
`;

const OptionalExtrasSection: React.FC<{ policy: Policy }> = ({ policy }) => {
  const informationText = useStaticQuery<OptionalExtrasData>(query).csMyPolicyPage
    .optional_extras.information_text;

  return (
    <AccordionItemWrapper>
      <OptionalExtras policy={policy} headingsAs="h3" />
      <RichTextWithTopMargin html={informationText} />
    </AccordionItemWrapper>
  );
};

export default OptionalExtrasSection;
