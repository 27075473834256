import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import LinkWithIcon from 'components/LinkWithIcon';

export const SectionHeading = styled.h2`
  ${fonts.headingMedium};
  margin-bottom: ${spacing(-3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(-1)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(-2)};
  `}
`;

export const StyledLinkWithIcon = styled(LinkWithIcon)`
  margin-bottom: ${spacing(3)};
  margin-right: ${spacing(3)};
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing(-2)};

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
    margin-bottom: ${spacing(-3)};
  `}

  ${mediaQuery.tabletLandscape`
    flex-direction: column;
    margin-bottom: ${spacing(-2)};
  `}
`;

export const StyledText = styled.p`
  margin: 0;
`;

export const NoChangesCanBeMade = styled(RichText)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
  margin-top: ${spacing(8)};
  `}
`;
