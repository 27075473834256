import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import ChevronBackground from '@rsa-digital/evo-shared-components/components/Hero/ChevronBackground';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { trackButtonClick } from 'helpers/eventTracking';
import { getPolicyTier, isPolicyInactive } from 'helpers/policyHelpers';
import { accountRoutes } from 'helpers/routingHelper';
import { Policy } from 'state/policy/policy';
import {
  ButtonWrapper,
  HeroGrid,
  IconWrapper,
  StyledButtonLink,
  StyledHeroWrapper,
  StyledInactivePolicyCard,
  StyledPolicyCard,
  StyledTieredPolicyCard,
} from './styles';

export type PolicyHeroProps = {
  policy: Policy | null;
  onCancelMta: () => void;
} & ComponentProps;

type MyPolicies = {
  csMyPolicyPage: {
    back_button_text: string;
  };
};

const query = graphql`
  query {
    csMyPolicyPage {
      back_button_text
    }
  }
`;

const PolicyHero: React.FC<PolicyHeroProps> = ({ policy, onCancelMta, ...props }) => {
  const staticData = useStaticQuery<MyPolicies>(query);

  return (
    <StyledHeroWrapper {...componentProps(props)}>
      <ChevronBackground>
        <HeroGrid alignLeft>
          <GridItem desktop={10} tabletLandscape={10}>
            <StyledButtonLink
              id="backButton"
              onClick={() => {
                trackButtonClick('backButton', 'Back');
                navigate(accountRoutes.account);
              }}>
              <ButtonWrapper>
                <IconWrapper>
                  <Icon name="chevron" size="small" flipX />
                </IconWrapper>
                {staticData.csMyPolicyPage.back_button_text}
              </ButtonWrapper>
            </StyledButtonLink>
          </GridItem>
        </HeroGrid>
      </ChevronBackground>
      {policy &&
        !getPolicyTier(policy) &&
        (isPolicyInactive(policy) ? (
          <StyledInactivePolicyCard policy={policy} isPolicyPage />
        ) : (
          <StyledPolicyCard policy={policy} onCancelMta={onCancelMta} />
        ))}
      {policy && getPolicyTier(policy) && (
        <StyledTieredPolicyCard
          policy={policy}
          isInactive={isPolicyInactive(policy)}
          onCancelMta={onCancelMta}
        />
      )}
    </StyledHeroWrapper>
  );
};

export default PolicyHero;
