import { InputReactElement } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { IconSize } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { CsIcon } from 'types/contentStack';
import { ButtonWrapper, IconWrapper, StyledButtonLink } from './styles';

export type LinkWithIconProps = {
  children: InputReactElement;
  onClick: () => void;
  icon: CsIcon | undefined;
  iconSize?: IconSize;
} & ComponentProps;

const LinkWithIcon: React.FC<LinkWithIconProps> = ({
  children,
  onClick,
  icon,
  iconSize,
  ...props
}) => {
  return (
    <StyledButtonLink {...componentProps(props)} onClick={onClick}>
      <ButtonWrapper>
        {icon && (
          <IconWrapper>
            <Icon name={icon.icon_code} size={iconSize || 'small'} />
          </IconWrapper>
        )}
        {children}
      </ButtonWrapper>
    </StyledButtonLink>
  );
};

export default LinkWithIcon;
