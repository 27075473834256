import RemoveButton from '@rsa-digital/evo-shared-components/components/RemoveButton';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  margin: ${spacing(2)} ${spacing(3)} 0;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} ${spacing(7)} 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(2)} ${spacing(8)} ${spacing(3)};
  `}
`;

export const ModalHeading = styled.h2`
  ${fonts.headingSmall}

  margin: 0 0 ${spacing(4)};
`;

export const RemoveButtonWithMargin = styled(RemoveButton)`
  margin-top: ${spacing(4)};
`;
