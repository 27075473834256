import { QuoteAdditionalDriverDetails } from 'api/quote/quoteRequest';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { trackButtonClick } from 'helpers/eventTracking';
import replaceCsPlaceholders, { CsPlaceholders } from 'helpers/replaceCsPlaceholders';

type ConfirmRemoveDriverModalProps = {
  additionalDriver: QuoteAdditionalDriverDetails;
  onConfirm: MouseEventHandler;
  onClose: MouseEventHandler;
};

export type CsRemoveDriverModal = {
  csPolicyDetails: {
    drivers_details: {
      confirm_remove_driver_modal: {
        heading: string;
        body: string;
        confirm_removal_cta_text: string;
        confirm_removal_cta_screenreader_text: string;
        cancel_removal_text: string;
        cancel_removal_screenreader_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPolicyDetails {
      drivers_details {
        confirm_remove_driver_modal {
          heading
          body
          confirm_removal_cta_text
          confirm_removal_cta_screenreader_text
          cancel_removal_text
          cancel_removal_screenreader_text
        }
      }
    }
  }
`;

const csConfirmRemoveDriverPlaceholders: CsPlaceholders<QuoteAdditionalDriverDetails> = {
  fullName: (additionalDriver: QuoteAdditionalDriverDetails) =>
    `${additionalDriver.firstName} ${additionalDriver.lastName}`,
};

const ConfirmRemoveDriverModal: React.FC<ConfirmRemoveDriverModalProps> = ({
  additionalDriver,
  onConfirm,
  onClose,
}) => {
  const content = useStaticQuery<CsRemoveDriverModal>(query);
  const modalCopy = replaceCsPlaceholders(
    content.csPolicyDetails.drivers_details.confirm_remove_driver_modal,
    additionalDriver,
    csConfirmRemoveDriverPlaceholders
  );

  return (
    <ConfirmationModal
      data-cy="Confirm Remove Driver modal"
      heading={modalCopy.heading}
      body={modalCopy.body}
      onClose={(e) => {
        trackButtonClick('mtaConfirmationModal', modalCopy.cancel_removal_text);
        onClose(e);
      }}
      confirmCta={{
        displayText: modalCopy.confirm_removal_cta_text,
        screenReaderText: modalCopy.confirm_removal_cta_screenreader_text,
        onClick: (e) => {
          trackButtonClick('mtaConfirmationModal', modalCopy.confirm_removal_cta_text);
          onConfirm(e);
        },
      }}
      cancelActionLink={{
        displayText: modalCopy.cancel_removal_text,
        screenReaderText: modalCopy.cancel_removal_screenreader_text,
      }}
      data-pii-mask
    />
  );
};

export default ConfirmRemoveDriverModal;
