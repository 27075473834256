import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: ${spacing(1)} 0 ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} 0 0;
  `}

  ${mediaQuery.desktop`
    margin-top: ${spacing(1)};
  `}
`;

export const AddDriverSection = styled.div`
  margin: ${spacing(2)} 0;
  padding-top: ${spacing(4)};
  width: 100%;
  border-top: 2px solid ${colors.neutral06};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(3)} 0 0;
    padding-top: ${spacing(4)};
  `}
`;
