import SelectInput from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Modal from '@rsa-digital/evo-shared-components/components/Modal';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { QuoteAdditionalDriverDetails } from 'api/quote/quoteRequest';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler, useState } from 'react';
import { trackButtonClick, trackDropdownSelect } from 'helpers/eventTracking';
import { ModalHeading, ModalWrapper, RemoveButtonWithMargin } from './styles';

export type SelectDriverToRemoveModalProps = {
  onConfirm: (driverId: string) => MouseEventHandler;
  onClose: MouseEventHandler;
  additionalDrivers: QuoteAdditionalDriverDetails[];
} & ComponentProps;

export type CsSelectDriverToRemoveModal = {
  csMyPolicyPage: {
    select_driver_to_remove_modal: {
      heading: string;
      remove_button_text: string;
      remove_button_screenreader_text: string;
    };
  };
};

const query = graphql`
  query {
    csMyPolicyPage {
      select_driver_to_remove_modal {
        heading
        remove_button_text
        remove_button_screenreader_text
      }
    }
  }
`;

const SelectDriverToRemoveModal: React.FC<SelectDriverToRemoveModalProps> = ({
  additionalDrivers,
  onConfirm,
  onClose,
  ...props
}) => {
  const headingId = 'select-driver-modal-heading';

  const modalContent = useStaticQuery<CsSelectDriverToRemoveModal>(query).csMyPolicyPage
    .select_driver_to_remove_modal;

  const driverOptions = additionalDrivers
    .filter((driver) => !driver.isMainDriver)
    .map((driver) => ({
      name: `${driver.firstName} ${driver.lastName}`,
      value: driver.driverId,
    }));

  const [driverIdToRemove, setDriverIdToRemove] = useState(() =>
    driverOptions.length === 1 ? driverOptions[0].value : ''
  );

  return (
    <Modal
      data-cy="Select Driver To Remove modal"
      {...componentProps(props)}
      aria-labelledby={headingId}
      onClose={(e) => {
        trackButtonClick('closeRemoveDriverModal', modalContent.heading);
        onClose(e);
      }}>
      <ModalWrapper>
        <ModalHeading id={headingId}>{modalContent.heading}</ModalHeading>
        <Grid alignLeft>
          <GridItem desktop={5}>
            <SelectInput
              aria-labelledby={headingId}
              id="remove-driver-dropdown"
              value={driverIdToRemove}
              options={driverOptions}
              onChange={(e) => {
                trackDropdownSelect('remove-driver-dropdown', e.target.value);
                setDriverIdToRemove(e.target.value);
              }}
              disabled={driverOptions.length === 1}
            />
          </GridItem>
        </Grid>
        <RemoveButtonWithMargin
          screenReaderText={modalContent.remove_button_screenreader_text}
          disabled={!driverIdToRemove}
          onClick={(e) => {
            trackButtonClick('removeDriverButton', modalContent.remove_button_text);
            onConfirm(driverIdToRemove)(e);
          }}>
          {modalContent.remove_button_text}
        </RemoveButtonWithMargin>
      </ModalWrapper>
    </Modal>
  );
};

export default SelectDriverToRemoveModal;
