import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { isComprehensiveCover } from 'api/businessLogic/quote';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { AccordionItemWrapper } from 'components/AccordionItemWrapper';
import Excesses from 'components/PolicyDetails/Excesses';
import RichTextWithModal from 'components/RichTextWithModal';
import replaceQuotePlaceholders from 'helpers/replaceQuotePlaceholders';
import { Policy } from 'state/policy/policy';

type ExcessData = {
  csPolicyDetails: {
    excess: {
      fire_and_theft_excess_text: string;
    };
  };
};

const query = graphql`
  query {
    csPolicyDetails {
      excess {
        fire_and_theft_excess_text
      }
    }
  }
`;

const ExcessSection: React.FC<{ policy: Policy }> = ({ policy }) => {
  const data = useStaticQuery<ExcessData>(query);
  const fireAndTheftText = replaceQuotePlaceholders<ExcessData>(data, policy)
    .csPolicyDetails.excess.fire_and_theft_excess_text;

  return (
    <AccordionItemWrapper>
      {isComprehensiveCover(policy.policy.coverWanted) ? (
        <Grid alignLeft>
          <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
            <Excesses quote={policy} />
          </GridItem>
        </Grid>
      ) : (
        <RichTextWithModal html={fireAndTheftText} />
      )}
    </AccordionItemWrapper>
  );
};

export default ExcessSection;
